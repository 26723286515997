import React from 'react';
import { bool } from 'prop-types';
import BoatSizeRangeFilterPlain from './BoatSizeRangeFilterPlain';
import BoatSizeRangeFilterPopup from './BoatSizeRangeFilterPopup';

const BoatSizeRangeFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <BoatSizeRangeFilterPopup {...rest} />
  ) : (
    <BoatSizeRangeFilterPlain {...rest} />
  );
};

BoatSizeRangeFilter.defaultProps = {
  showAsPopup: false,
};

BoatSizeRangeFilter.propTypes = {
  showAsPopup: bool,
};

export default BoatSizeRangeFilter;
