import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import WaterDepthFilterForm from './WaterDepthForm/WaterDepthForm';

import css from './WaterDepthRangeFilterPlain.module.css';

const RADIX = 10;

const getWaterDepthQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'pub_maxWaterDepth';
};

// Parse value, which should look like "0,1000"
const parse = waterDepthRange => {
  const [minWaterDepth, maxWaterDepth] = !!waterDepthRange
    ? waterDepthRange.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  // Note: we compare to null, because 0 as minWaterDepth is falsy in comparisons.
  return !!waterDepthRange && minWaterDepth != null && maxWaterDepth != null
    ? { minWaterDepth, maxWaterDepth }
    : null;
};

// Format value, which should look like { minWaterDepth, maxWaterDepth }
const format = (range, queryParamName) => {
  const { minWaterDepth, maxWaterDepth } = range || {};
  // Note: we compare to null, because 0 as minWaterDepth is falsy in comparisons.
  const value =
    minWaterDepth != null && maxWaterDepth != null ? `${minWaterDepth},${maxWaterDepth}` : null;
  return { [queryParamName]: value };
};

class WaterDepthRangeFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, queryParamNames } = this.props;
    const waterDepthQueryParamName = getWaterDepthQueryParamName(queryParamNames);
    onSubmit(format(values, waterDepthQueryParamName));
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    const waterDepthQueryParamName = getWaterDepthQueryParamName(queryParamNames);
    onSubmit(format(null, waterDepthQueryParamName));
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      min,
      max,
      step,
      intl,
      currencyConfig,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const waterDepthQueryParam = getWaterDepthQueryParamName(queryParamNames);
    const initialWaterDepth = initialValues ? parse(initialValues[waterDepthQueryParam]) : {};
    const { minWaterDepth, maxWaterDepth } = initialWaterDepth || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minWaterDepth) && hasValue(maxWaterDepth);

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;
    const labelText = hasInitialValues
      ? intl.formatMessage({ id: 'WaterDepthFilterForm.labelSelectedPlain' })
      : label
      ? label
      : intl.formatMessage({ id: 'WaterDepthFilterForm.label' });

    return (
      <div className={classes}>
        <div className={labelClass}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={labelClass}>{labelText}</span>
          </button>
          <button type="button" className={css.clearButton} onClick={this.handleClear}>
            <FormattedMessage id={'WaterDepthFilterForm.clear'} />
          </button>
        </div>
        <div className={css.formWrapper}>
          <WaterDepthFilterForm
            id={id}
            initialValues={
              hasInitialValues ? initialWaterDepth : { minWaterDepth: min, maxWaterDepth: max }
            }
            onChange={this.handleChange}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            min={min}
            max={max}
            step={step}
            liveEdit
            isOpen={this.state.isOpen}
          />
        </div>
      </div>
    );
  }
}

WaterDepthRangeFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  step: number,
};

WaterDepthRangeFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    waterDepth: string,
  }),
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const WaterDepthRangeFilterPlain = injectIntl(WaterDepthRangeFilterPlainComponent);

export default WaterDepthRangeFilterPlain;
