import React from 'react';
import { bool } from 'prop-types';
import WaterDepthRangeFilterPopup from './WaterDepthRangeFilterPopup';
import WaterDepthRangeFilterPlain from './WaterDepthRangeFilterPlain';

const WaterDepthRangeFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <WaterDepthRangeFilterPopup {...rest} />
  ) : (
    <WaterDepthRangeFilterPlain {...rest} />
  );
};

WaterDepthRangeFilter.defaultProps = {
  showAsPopup: false,
};

WaterDepthRangeFilter.propTypes = {
  showAsPopup: bool,
};

export default WaterDepthRangeFilter;
